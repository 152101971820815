import React from 'react';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import Title from '../components/Title';
import Form from '../components/Form';

const optionsCutting = [
  { key: 'None', value: 'None (Default)' },
  { key: 'Die Cut', value: 'Die Cut' },
  { key: 'Butt Cut', value: 'Butt Cut' },
  { key: 'Pre Cut', value: 'Pre Cut' },
  { key: 'Special Die', value: 'Special Die' },
  { key: 'Computer Perf', value: 'Computer Perf' }
];

const optionsCutterShape = [
  { key: 'rectangle', value: 'Rectangle (Default)' },
  { key: 'circle', value: 'Circle' },
  { key: 'oval', value: 'Oval' },
  { key: 'triangle', value: 'Triangle' },
  { key: 'special', value: 'Special (please supply copy)' }
];

const optionsLeadingEdge = [
  { key: 'WEL', value: 'Wide Edge Leading' },
  { key: 'NEL', value: 'Narrow Edge Leading' },
  { key: 'AWL', value: 'Any Way Leading' }
];

const optionsNumberOfColours = [
  { key: 'none', value: 'None' },
  { key: '1 spot', value: '1 spot colour' },
  { key: '2 spot', value: '2 spot colours' },
  { key: '3 spot', value: '3 spot colours' },
  { key: '4 spot', value: '4 spot colours' },
  { key: '4 col process', value: '4 colour process' },
  { key: '4 col process + spot', value: '4 colour process + extra colour' },
  { key: '1 spot pan', value: '1 spot colour Pantone match' },
  { key: '2 spot pan', value: '2 spot colour Pantone match' },
  { key: '3 spot pan', value: '3 spot colour Pantone match' },
  { key: '4 spot pan', value: '4 spot colour Pantone match' }
];

const optionsSpecialExtras = [
  { key: 'none', value: 'None' },
  { key: 'domed', value: 'Domed' },
  { key: 'numbered', value: 'Numbered' },
  { key: "splitLam'", value: "'Split Lam'" },
  { key: 'sprocketing', value: 'Sprocketing' },
  { key: 'cutSingle', value: 'Cut Single' },
  { key: 'embossed', value: 'Embossed' },
  { key: 'fanFolded', value: 'Fan Folded' },
  { key: 'sheeted', value: 'Sheeted' },
  { key: 'overprinting', value: 'Overprinting' }
];

const schema = Yup.object().shape({
  company: Yup.string().required('Company Name is required'),
  contact: Yup.string().required('Contact is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  size: Yup.string().required('Size is required'),
  // cutting: Yup.string().required(),
  // cutterShape: Yup.string().required(),
  quantity1: Yup.string().required('Quantity 1 is required'),
  noOfKinds: Yup.string().required('No. of Kinds is required'),
  noOfColours: Yup.string().required('No. of Colours is required'),
  baseMaterial: Yup.string().required('Base Material is required'),
  baseColour: Yup.string().required('Base Colour is required'),
  baseAdhesive: Yup.string().required('Base Adhesive is required')
});

const initialValues = {
  company: '',
  contact: '',
  email: '',
  phone: '',
  size: '',
  cutting: '',
  cutterShape: '',
  leadingEdge: '',
  quantity1: '',
  quantity2: '',
  quantity3: '',
  noOfKinds: '',
  noOfColours: '',
  screenTintRequired: '',
  baseMaterial: '',
  specificMaterial: '',
  baseColour: '',
  baseAdhesive: '',
  laminateOrVarnish: '',
  specificLaminateOrVarnish: '',
  specialExtras: '',
  comment: ''
};

const fields = [
  { name: 'company', label: 'Company Name', type: 'input', required: true },
  { name: 'contact', label: 'Contact', type: 'input', required: true },
  { name: 'email', label: 'Email', type: 'input', required: true },
  { name: 'phone', label: 'Phone', type: 'input', required: true },
  // Three different inputs?
  {
    name: 'size',
    label: 'Size',
    type: 'input',
    placeholder: 'i.e. 100 x 50 mm',
    required: true
  },
  {
    name: 'cutting',
    label: 'Cutting',
    placeholder: 'Cutting',
    type: 'select',
    options: optionsCutting,
    required: true
  },
  {
    name: 'cutterShape',
    label: 'Cutter Shape',
    placeholder: 'Cutter Shape',
    type: 'select',
    options: optionsCutterShape,
    required: true
  },
  {
    name: 'leadingEdge',
    label: 'Leading Edge',
    placeholder: 'Leading Edge',
    type: 'select',
    options: optionsLeadingEdge
  },
  { name: 'quantity1', label: 'Quantity 1', type: 'input', required: true },
  { name: 'quantity2', label: 'Quantity 2', type: 'input' },
  { name: 'quantity3', label: 'Quantity 3', type: 'input' },
  { name: 'noOfKinds', label: 'No. of Kinds', type: 'number', min: 0, required: true },
  {
    name: 'noOfColours',
    label: 'Number of Print Colours',
    type: 'select',
    options: optionsNumberOfColours,
    size: optionsNumberOfColours.length,
    hint: 'hold control to make multiple selections',
    required: true,
    multiple: true
  },
  // Checkbox?
  {
    name: 'screenTintRequired',
    label: 'Screen or Tint required',
    type: 'select',
    options: [{ key: 'no', value: 'No' }, { key: 'yes', value: 'Yes' }]
  },
  { name: 'baseMaterial', label: 'Base Material', type: 'input', required: true },
  { name: 'specificMaterial', label: 'Specific Material', type: 'input' },
  { name: 'baseColour', label: 'Base Colour', placeholder: 'i.e. matt red', type: 'input', required: true },
  { name: 'baseAdhesive', label: 'Base Adhesive', placeholder: 'i.e. peelable', type: 'input', required: true },
  // Checkbox?
  {
    name: 'laminateOrVarnish',
    label: 'Laminate/Varnish',
    type: 'select',
    options: [{ key: 'no', value: 'No' }, { key: 'yes', value: 'Yes' }]
  },
  {
    name: 'specificLaminateOrVarnish',
    label: 'Specific Laminate/Varnish',
    type: 'input',
    condition: {
      key: 'laminateOrVarnish',
      value: 'yes'
    }
  },
  {
    name: 'specialExtras',
    label: 'Special Extras',
    type: 'select',
    options: optionsSpecialExtras,
    size: optionsSpecialExtras.length,
    hint: 'hold control to make multiple selections',
    multiple: true
  },
  { name: 'comment', label: 'Comment', type: 'textarea', placeholder: 'i.e. numbered from / to' }
];

const Label = () => (
  <Layout title="Enquiry Form">
    <div className="c-content-box c-size-md">
      <Title
        main="Enquiry Form"
        helper="From your details we will determine the most suitable printing process and then forward a
                    <strong>quote</strong> with the best possible price! <br /> Quotes will be returned via email within <strong>4 working hours</strong> from receipt.<br /> <br />
                    This service is available to <strong>TRADE ONLY</strong> customers and is subject to sight of copy. <br /><br />
                    <strong>*</strong> Indicates a required field"
      />
      <div className="container">
        <div className="c-content-feedback-1 c-option-2">
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="c-container c-bg-white c-border-radius">
                <div className="c-content-title-1">
                  <h2 className="c-font-uppercase c-font-bold c-font-dark c-font-center c-font-30">Receive a Quote</h2>
                  <div className="c-line-center c-theme-bg" />

                  <Form
                    subject="New Enquiry at Calf Hey"
                    schema={schema}
                    fields={fields}
                    initialValues={initialValues}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Label;
